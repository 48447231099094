// import * as d3 from 'd3'
import * as d3 from 'd3'
import * as THREE from 'three'
import gsap from 'gsap'

const canvas = document.querySelector('#globe')

window.addEventListener('frame-ready', () => {
    if (canvas) {
        new Globe()
    }
})

class Globe {

    constructor () {
        //canvas.
        this.wrapper = document.querySelector('#globe-wrapper');
        this.WIDTH = canvas.getBoundingClientRect().width;
        this.HEIGHT = this.WIDTH
        //this.HEIGHT = canvas.getBoundingClientRect().height;

        this.interface()
        this.events()
        this.fetchData()
            .then(() => {

                const country = this.ui.buttons.find(el => {
                    return el.classList.contains('active')
                }).getAttribute('data-country')
                this.updateActiveCountry(country)

                this.setupScene()
                requestAnimationFrame(() => { this.render() });

                this.goTo(this.activeCountry)
            })
    }

    interface() {
        this.ui = {
            buttons: Array.from(document.querySelectorAll('.globe-countries a')),
            deactivateButtons: () => {
                this.ui.buttons.forEach( button => {
                    button.classList.remove('active')
                })
            },
            activateButton: (button) => {
                button.classList.add('active')
            }
        }
    }

    events() {
        // Hover timeout
        let enterIntent
        let leaveIntent

        this.ui.buttons.forEach( button => {
            /*button.addEventListener('click', () => {
                this.ui.deactivateButtons()
                this.ui.activateButton(button)
            })*/

            button.addEventListener('mouseenter', e => {
                let button = e.target;
                clearTimeout(leaveIntent)
                this.ui.deactivateButtons()
                this.ui.activateButton(button)
                enterIntent = setTimeout(() => {
                    this.updateActiveCountry(button.getAttribute('data-country'))
                    this.goTo(this.activeCountry)
                    this.WORLD.material.map = this.setupTexture()
                    this.WORLD.material.map.needsUpdate = true
                }, 400)
            })

            button.addEventListener('mouseout', () => {
                clearTimeout(enterIntent)
                leaveIntent = setTimeout(() => {
                    const country = this.ui.buttons.find(item => {
                        return item.classList.contains('active')
                    }).getAttribute('data-country')
                    this.updateActiveCountry(country)
                    this.goTo(this.activeCountry)
                    this.WORLD.material.map = this.setupTexture()
                    this.WORLD.material.map.needsUpdate = true
                }, 400)
            })
        })
    }

    async fetchData() {
        this.WORLD_JSON = await d3.json("/_resources/themes/goodmanfielder/build/data/geo-trim.json")
    }

    setupScene() {
        this.SCENE = new THREE.Scene();
        this.setupLights()
        this.setupCamera()
        this.setupRenderer()
        this.setupWorld()
    }

    setupLights() {
        this.LIGHT = new THREE.DirectionalLight(0xFFFFFF, .6)
        this.LIGHT.position.set(-1, 2, 4)
        this.SCENE.add(this.LIGHT)
        this.AMBIENT = new THREE.AmbientLight(0x404040, 3)
        this.SCENE.add(this.AMBIENT);
    }

    setupCamera() {
        this.FOV = 33
        this.CAMERA = new THREE.PerspectiveCamera(
            this.FOV, // field of view (fov) in degrees
            this.WIDTH / this.HEIGHT, // aspect ratio
            0.01, // near field distance
            1000 // far field distance
        );
        this.CAMERA.position.set(0, 0, 4);
    }

    setupRenderer() {
        this.RENDERER = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            canvas: canvas
        });
        this.RENDERER.setSize(this.WIDTH, this.HEIGHT)
    }

    setupWorld() {
        this.SPHERE_GEOMETRY = new THREE.SphereGeometry(1, 250, 250);
        this.SPHERE_MATERIAL = new THREE.MeshPhongMaterial({
            map: this.setupTexture()
        })

        this.WORLD = new THREE.Mesh(
            this.SPHERE_GEOMETRY, // geometry for mesh
            this.SPHERE_MATERIAL
        );

        this.SCENE.add(this.WORLD)
    }

    setupTexture() {

        const PROJECTION_AR = 2 // Aspect ratio (width / height) of chosen projection (equirectangular)
        const CANVAS_WIDTH = 4096 // Maximum width allowed for canvas elements on mobile
        const CANVAS_HEIGHT = CANVAS_WIDTH / PROJECTION_AR // Canvas height from maximum width and projection aspect ratio

        const PROJECTION = d3
            .geoEquirectangular()
            .translate([CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2])
            .scale(
                Math.min(CANVAS_WIDTH / PROJECTION_AR / Math.PI, CANVAS_HEIGHT / Math.PI)
            );

        // Append canvas and save reference
        const canvas = d3
            .select("body")
            .append("canvas")
            .attr("width", `${CANVAS_WIDTH - 20}px`)
            .attr("height", `${CANVAS_HEIGHT - 20}px`);

        // Get 2d context of canvas
        const context = canvas.node().getContext("2d");

        // Create geo path generator
        const path = d3
            .geoPath()
            .projection(PROJECTION)
            .context(context);

        context.fillStyle = '#C2CAC1';
        context.fillRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

        this.WORLD_JSON.features.forEach((d) => {

            context.fillStyle = getComputedStyle(document.body).getPropertyValue('--primary')
            context.strokeStyle = getComputedStyle(document.body).getPropertyValue('--primary')

            if (d.properties.admin === this.activeCountry.admin) {
                context.fillStyle = getComputedStyle(document.body).getPropertyValue('--gold')
                context.strokeStyle = getComputedStyle(document.body).getPropertyValue('--gold')
            }

            context.beginPath();
            path(d);
            context.fill();
            context.stroke();
        });

         // Generate texture from canvas
        const texture = new THREE.Texture(canvas.node());
        texture.needsUpdate = true;

        // Remove canvas
        canvas.remove();

        // Return texture
        return texture;
    }

    updateActiveCountry(country) {
        this.activeCountry = this.WORLD_JSON.features.find(item => {
            return item.properties.admin === country
        }).properties
    }

    goTo(country) {
        let tl = gsap.timeline()

        if(this.CAMERA.fov !== country.fov ) {
            tl.to(this.CAMERA, {
                fov: this.FOV
            })
        }

        tl.to(this.WORLD.rotation, {
            x: country.x,
            y: country.y,
            duration: 1,
            ease: 'power2.inOut'
        })

        if(country.fov) {
            tl.to(this.CAMERA, {
                fov: country.fov,
                ease: 'power2.inOut'
            }, "-=.7")

            if(country.fov < 20) {
                tl.to(this.AMBIENT, {
                    intensity: 2
                }, "-=.5")
            } else {
                tl.to(this.AMBIENT, {
                    intensity: 3
                }, "-=.5")
            }
        } else {
            tl.to(this.AMBIENT, {
                intensity: 3
            }, "-=.5")
        }
    }

    render() {
        requestAnimationFrame(() => { this.render() });

        const cw = canvas.getBoundingClientRect().width
        const pw = canvas.parentElement.getBoundingClientRect().width

        if(cw !== pw) {
            canvas.setAttribute('style', `width: ${pw}px; height: ${pw}px;`)
        }

        this.RENDERER.render(this.SCENE, this.CAMERA);
        this.CAMERA.updateProjectionMatrix()
    }
}
